import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-strategict-programs',
  template: '<router-outlet>',
  styleUrls: []
})
export class StrategictProgramsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

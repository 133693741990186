import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-sector',
  templateUrl: './business-sector.component.html',
  styleUrls: ['./business-sector.component.css']
})
export class BusinessSectorComponent implements OnInit {
  navColor: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}

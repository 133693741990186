import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  navColor: boolean = true;
  showLoad = false;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder, private service: ContactService) { document.documentElement.scrollTop = 0;
    this.doForm();
    console.log("v1.1.0");
  }

  //Cambiar navbar
  @HostListener('window:viewport', [])
   onWindowScroll() {
    console.log(window.pageXOffset);

  }

  ngOnInit(): void {
  }
  doForm(){
    this.form = this.formBuilder.group({
      name: ['',Validators.required],
      country: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      phone: ['',Validators.required],
      message: ['',Validators.required],
      aviso: [false,Validators.requiredTrue],
    });
  }
  submit(){
    this.showLoad = true;
    if(this.form.invalid){
      this.form.markAllAsTouched();
      this.showLoad = false;
    } else {
      const dataJson = {
        "nombre":  this.form.value.name,
        "correo":  this.form.value.email,
        "pais":  this.form.value.country,
        "telefono":  this.form.value.phone,
        "empresa":  "N/A",
        "NumeroColaboradores":  0,
        "descripcion":  this.form.value.message,
    }
      this.service.sendContact(dataJson).subscribe( res => {
        this.showLoad = false;
        Swal.fire({
          icon: 'success',
          title: res.status,
          showConfirmButton: true,
          showCancelButton: true
        });
        this.doForm();
      }, error => {
        this.showLoad = false;
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un problema",
          showConfirmButton: true,
          showCancelButton: true
        });
      })
    }

  }

}

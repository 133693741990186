import { HostListener, Input } from '@angular/core';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// declare var $:JQueryStatic;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent  {

  @Input()  navColor: boolean = true;

  linksProgramas = [
    {text: 'Sector Empresarial', link: '/strategict-programs/bussines-sector'},
    {text: 'Sector Alimenticio', link: '/strategict-programs/food-sector'},
    {text: 'Sector Salud', link: '/strategict-programs/medical-sector'},
    {text: 'Sector de Tecnología', link: '/strategict-programs/technology-sector'},
    {text: 'Sector Académico', link: '/strategict-programs/academic-sector'},
    {text: 'Sector Gubernamental', link: '/strategict-programs/government-sector'},
    {text: 'Sector Sociedad civil y ONG ', link: '/strategict-programs/civil-society-and-ong-sector'},
  ];


  Shownav: boolean = false;
  Showsubnav: boolean = false;
  showIcon = true;
  Shownav_compu: boolean = true;
  // Shownav_compu: boolean = true;
  // Shownav_compu: boolean = true;
  // Shownav_compu: boolean = true;

  constructor(  ) {     }

  ngOnInit() {

  }

  show() {
    let shows = document.getElementsByClassName('show');
    // $(shows[0]).removeClass('show');
  }

  mostrar(){
      this.Shownav = !this.Shownav;
      this.Showsubnav = false;
    }

  ocultar(){
    this.Shownav = false;
    this.Showsubnav = false;
}

ocultar_compu(){
  this.  Shownav_compu = false;
}


  // translate(lang: string) {
  //   this.translateS.use(lang);
  // }

  //Cambiar navbar
  @HostListener('window:scroll', [])
   onWindowScroll() {
    // console.log(window.pageYOffset);



  }


}




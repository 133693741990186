import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-academic-sector',
  templateUrl: './academic-sector.component.html',
  styleUrls: ['./academic-sector.component.css']
})
export class AcademicSectorComponent implements OnInit {
  navColor: boolean = true;
  constructor() { document.documentElement.scrollTop = 0; }

  ngOnInit(): void {
  }

}

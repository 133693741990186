import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  navColor: boolean = true;

  slider: any;
  sliderSection: any;
  sliderSelectionLast: any;

  btnNext: any;
  btnPrev: any;

  intervalo: any;

  activoB: number = 0;

  constructor(private router: Router) { document.documentElement.scrollTop = 0; }

  ngOnInit(): void {
    this.sliderFunction();
    this.automatico();
    this.acordeon();
  }
  //Cambiar navbar
  @HostListener('window:scroll', [])
   onWindowScroll() {
    // console.log(window.pageYOffset);
    // let nav = document.querySelector('.nav_home');
    if(window.pageYOffset >= 375){
    this.navColor = false;
      //  nav.classList.add('nav_pegado');
      //  console.log('navColor')
    } else {
      this.navColor = true;
    }

  }

  sliderFunction(){
    this.slider = document.querySelector("#slider");
    this.sliderSection = document.querySelectorAll(".slider__section");
    this.sliderSelectionLast = this.sliderSection[this.sliderSection.length - 1];

    this.btnNext = document.querySelector("#btn_next");
    this.btnNext = document.querySelector("#btn_prev")

    this.slider.insertAdjacentElement('afterbegin',this.sliderSelectionLast);

    this.activoB = 1;
  }

  next(){
    clearInterval(this.intervalo); this.automatico();
    this.activoB++;
    if(this.activoB > 7){
      this.activoB = 1;
    }
    let sliderSectionFirst = document.querySelectorAll(".slider__section")[0];
    if(this.slider != undefined){
      this.slider.style.marginLeft = "-200%";
      this.slider.style.transition = "all 0.5s";
    }
    setTimeout(function(){
      if(this.slider != undefined){
        this.slider.style.transition = "none";
        this.slider.insertAdjacentElement('beforeend', sliderSectionFirst);
        this.slider.style.marginLeft = "-100%";
      }
    },500);
  }

  prev(){
    clearInterval(this.intervalo); this.automatico();
    this.activoB--;
    if(this.activoB >= 0){
      this.activoB = 7;
    }

    let sliderSection = document.querySelectorAll(".slider__section");
    let sliderSectionLast = sliderSection[sliderSection.length - 1];
    this.slider.style.marginLeft = "0";
    this.slider.style.transition = "all 0.5s";
    setTimeout(function(){
      this.slider.style.transition = "none";
      this.slider.insertAdjacentElement('afterbegin', sliderSectionLast);
      this.slider.style.marginLeft = "-100%";
    },500);
  }

  automatico(){
    this.intervalo = setInterval(()=>{
      this.next();
    }, 5 * 1000);
  }

  seccion(seleccion: number){
    let sliderSection;
    let arbol = document.querySelectorAll(".slider__section");
    console.log(arbol);
    let actual = parseInt(arbol[0].id.split("Element")[1]);
    let actualAux = actual + 1;
    if(actualAux > 6){
      actualAux = 0;
    }
    console.log("Del "+ actualAux + " al " + seleccion);
    let pasos = seleccion - actualAux;
    console.log("Pasos ",pasos);

    this.activoB = seleccion + 1;

    if( pasos == 0 ){
      console.log("misma posicion");
    }else{
      switch(pasos){
        case 1:
        case -6:
          this.next();
        break;
        case 2:
        case -5:
          clearInterval(this.intervalo); this.automatico();
          sliderSection = document.querySelectorAll(".slider__section");
          this.slider.style.marginLeft = "-300%";
          this.slider.style.transition = "all 0.5s";
          setTimeout(function(){
            this.slider.style.transition = "none";
            this.slider.insertAdjacentElement('beforeend', sliderSection[0]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[1]);
            this.slider.style.marginLeft = "-100%";
          },500);
        break;
        case 3:
        case -4:
          clearInterval(this.intervalo); this.automatico();
          sliderSection = document.querySelectorAll(".slider__section");
          this.slider.style.marginLeft = "-400%";
          this.slider.style.transition = "all 0.75s";
          setTimeout(function(){
            this.slider.style.transition = "none";
            this.slider.insertAdjacentElement('beforeend', sliderSection[0]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[1]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[2]);
            this.slider.style.marginLeft = "-100%";
          },750);
        break;
        case 4:
        case -3:
          clearInterval(this.intervalo); this.automatico();
          sliderSection = document.querySelectorAll(".slider__section");
          this.slider.style.marginLeft = "-500%";
          this.slider.style.transition = "all 1s";
          setTimeout(function(){
            this.slider.style.transition = "none";
            this.slider.insertAdjacentElement('beforeend', sliderSection[0]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[1]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[2]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[3]);
            this.slider.style.marginLeft = "-100%";
          },1000);
        break;
        case 5:
        case -2:
          clearInterval(this.intervalo); this.automatico();
          sliderSection = document.querySelectorAll(".slider__section");
          this.slider.style.marginLeft = "-600%";
          this.slider.style.transition = "all 1.25s";
          setTimeout(function(){
            this.slider.style.transition = "none";
            this.slider.insertAdjacentElement('beforeend', sliderSection[0]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[1]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[2]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[3]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[4]);
            this.slider.style.marginLeft = "-100%";
          },1250);
        break;
        case 6:
          clearInterval(this.intervalo); this.automatico();
          sliderSection = document.querySelectorAll(".slider__section");
          this.slider.style.marginLeft = "-700%";
          this.slider.style.transition = "all 1.5s";
          setTimeout(function(){
            this.slider.style.transition = "none";
            this.slider.insertAdjacentElement('beforeend', sliderSection[0]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[1]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[2]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[3]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[4]);
            this.slider.insertAdjacentElement('beforeend', sliderSection[5]);
            this.slider.style.marginLeft = "-100%";
          },1500);
        break;
        case -1:
          this.prev();
        break;


      }
      console.log(arbol);
    }
  }

  acordeon(){
    let bloques = document.querySelectorAll(".bloque");
    let flecha = document.querySelectorAll(".flecha");
    let titulos = document.querySelectorAll(".bloque-title");

    titulos.forEach((titulo ,i) => {
      titulos[i].addEventListener('click', ()=>{
        if(bloques[i].classList.contains('activo')){
          bloques[i].classList.remove('activo');
          flecha[i].classList.remove('rotate');
        }else{
          bloques.forEach((bloque, i)=>{
            bloques[i].classList.remove('activo');
            flecha[i].classList.remove('rotate');
          });
          bloques[i].classList.add('activo');
          flecha[i].classList.add('rotate');

        }
      });
    });

  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-civil-society-and-ong-sector',
  templateUrl: './civil-society-and-ong-sector.component.html',
  styleUrls: ['./civil-society-and-ong-sector.component.css']
})
export class CivilSocietyAndOngSectorComponent implements OnInit {
  navColor: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}

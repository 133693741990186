import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-strategict-programs',
  templateUrl: './menu-strategict-programs.component.html',
  styleUrls: ['./menu-strategict-programs.component.css']
})
export class MenuStrategictProgramsComponent implements OnInit {
  linksProgramas = [
    {text: 'Sector Empresarial', link: '/strategict-programs/bussines-sector'},
    {text: 'Sector Alimenticio', link: '/strategict-programs/food-sector'},
    {text: 'Sector Salud', link: '/strategict-programs/medical-sector'},
    {text: 'Sector de Tecnología', link: '/strategict-programs/technology-sector'},
    {text: 'Sector Académico', link: '/strategict-programs/academic-sector'},
    {text: 'Sector Gubernamental', link: '/strategict-programs/government-sector'},
    {text: 'Sector Sociedad civil y ONG ', link: '/strategict-programs/civil-society-and-ong-sector'},
  ];

  constructor() { document.documentElement.scrollTop = 0; }

  ngOnInit(): void {
  }

}

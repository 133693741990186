import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {URL} from './../models/url.models'

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public url: string = URL;

  constructor(private http: HttpClient) { }

  // sendContact(data: any): Observable<any> {
  //   return this.http.post(this.url + 'contact/v2/contactanos/', data);
  // }
  sendContact(data: any): Observable<any> {
    return this.http.post(this.url + 'contact/web/v1/ApiCreCon/', data);
  }
  
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcademicSectorComponent } from './academic-sector/academic-sector.component';
import { BusinessSectorComponent } from './business-sector/business-sector.component';
import { CivilSocietyAndOngSectorComponent } from './civil-society-and-ong-sector/civil-society-and-ong-sector.component';
import { FoodSectorComponent } from './food-sector/food-sector.component';
import { GovernmentSectorComponent } from './government-sector/government-sector.component';
import { MedicalSectorComponent } from './medical-sector/medical-sector.component';
import { TechnologySectorComponent } from './technology-sector/technology-sector.component';

export const STRATEGICT_PROGRAMS_ROUTES: Routes = [
  {
    path: 'academic-sector',
    component: AcademicSectorComponent,
  },
  {
    path: 'bussines-sector',
    component: BusinessSectorComponent,
  },
  {
    path: 'civil-society-and-ong-sector',
    component: CivilSocietyAndOngSectorComponent,
  },
  {
    path: 'food-sector',
    component: FoodSectorComponent,
  },
  {
    path: 'government-sector',
    component: GovernmentSectorComponent,
  },
  {
    path: 'medical-sector',
    component: MedicalSectorComponent,
  },
  {
    path: 'technology-sector',
    component: TechnologySectorComponent,
  },

  {path: '**',pathMatch: 'full', redirectTo: 'bussines-sector'}
];

@NgModule({
    imports: [RouterModule.forRoot(STRATEGICT_PROGRAMS_ROUTES)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }

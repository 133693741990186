import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NoticeOfPrivacyComponent } from './components/notice-of-privacy/notice-of-privacy.component';
import { StrategictProgramsComponent } from './components/strategict-programs/strategict-programs.component';
import { STRATEGICT_PROGRAMS_ROUTES } from './components/strategict-programs/strategict-programs.routes';


const routes: Routes = [
    {
      path: '', 
      component: HomeComponent
    },
    {
      path: 'strategict-programs', 
      component: StrategictProgramsComponent,
      children: STRATEGICT_PROGRAMS_ROUTES
    },
    {
      path: 'about-us', 
      component: AboutUsComponent
    },
    {
      path: 'contact', 
      component: ContactComponent
    },
    {
      path: '404', 
      component: NotfoundComponent
    },
    {
      path: "notice-of-privacy",
      component: NoticeOfPrivacyComponent
    },
    {
      path: '**',
      pathMatch: 'full', 
      redirectTo: '404'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

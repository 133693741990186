import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-sector',
  templateUrl: './food-sector.component.html',
  styleUrls: ['./food-sector.component.css']
})
export class FoodSectorComponent implements OnInit {
  navColor: boolean = true;

  constructor() { document.documentElement.scrollTop = 0; }

  ngOnInit(): void {
  }

}

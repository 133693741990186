import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

/**Flex */
import { FlexLayoutModule } from '@angular/flex-layout';

/**Angular Material */
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATE_LOCALE } from '@angular/material/core';
/*COMPONENTES*/
import { HomeComponent } from './components/home/home.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StrategictProgramsComponent } from './components/strategict-programs/strategict-programs.component';
import { BusinessSectorComponent } from './components/strategict-programs/business-sector/business-sector.component';
import { FoodSectorComponent } from './components/strategict-programs/food-sector/food-sector.component';
import { MedicalSectorComponent } from './components/strategict-programs/medical-sector/medical-sector.component';
import { TechnologySectorComponent } from './components/strategict-programs/technology-sector/technology-sector.component';
import { AcademicSectorComponent } from './components/strategict-programs/academic-sector/academic-sector.component';
import { GovernmentSectorComponent } from './components/strategict-programs/government-sector/government-sector.component';
import { CivilSocietyAndOngSectorComponent } from './components/strategict-programs/civil-society-and-ong-sector/civil-society-and-ong-sector.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { MenuStrategictProgramsComponent } from './components/strategict-programs/menu-strategict-programs/menu-strategict-programs.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NoticeOfPrivacyComponent } from './components/notice-of-privacy/notice-of-privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotfoundComponent,
    NavbarComponent,
    StrategictProgramsComponent,
    BusinessSectorComponent,
    FoodSectorComponent,
    MedicalSectorComponent,
    TechnologySectorComponent,
    AcademicSectorComponent,
    GovernmentSectorComponent,
    CivilSocietyAndOngSectorComponent,
    AboutUsComponent,
    ContactComponent,
    MenuStrategictProgramsComponent,
    FooterComponent,
    LoadingComponent,
    NoticeOfPrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  exports: [
    CommonModule,
    TranslateModule
  ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technology-sector',
  templateUrl: './technology-sector.component.html',
  styleUrls: ['./technology-sector.component.css']
})
export class TechnologySectorComponent implements OnInit {
  navColor: boolean = true;

  constructor() { document.documentElement.scrollTop = 0; }

  ngOnInit(): void {
  }

}
